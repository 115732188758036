import type { RenderableTreeNodes } from '@markdoc/markdoc'
import Markdoc from '@markdoc/markdoc'
import * as React from 'react'
import { Image } from '~/components/image.tsx'
import { Youtube } from '~/components/markdown/youtube.tsx'
import { Box } from '~/components/markdown/box.tsx'
import { LinkButton } from '~/components/markdown/link.tsx'
import { Underline } from '~/components/markdown/underline.tsx'

type Props = { content: RenderableTreeNodes }

export function Markdown({ content }: Props) {
  return (
    <>
      {Markdoc.renderers.react(content, React, {
        components: { Image, Youtube, Box, LinkButton, Underline },
      })}
    </>
  )
}
