import { ReactPlayer } from '~/components/react-player.client.tsx'
import { useEffect, useState } from 'react'

type Props = {
  url: string
}

export function Youtube({ url }: Props) {
  const [showPlayer, setShowPlayer] = useState(false)

  useEffect(() => {
    setShowPlayer(true)
  }, [])

  if (!showPlayer) {
    return <div>Loading...</div>
  }

  return (
    <div className="aspect-video py-4">
      <ReactPlayer url={url} height="100%" width="100%" />
    </div>
  )
}

export let scheme = {
  render: Youtube.name,
  description: 'Displays a Youtube video',
  children: [],
  attributes: {
    url: {
      type: String,
      default: null,
    },
  },
}
